
import { defineComponent } from "vue";
import { Form, useForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Modal } from "bootstrap";

import * as Yup from "yup";

import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig } from "axios";
import ElectronicSignature from "@/components/modals/ElectronicSignature.vue";

interface TransportData {
  name: string;
  cuit: string;
}

export default defineComponent({
  name: "new-address-modal",
  components: {
    ElectronicSignature,
    Form,
  },
  data() {
    return {
      loading: false,
      requireReason: false,
      requireExtra: true,
      signatureType: "update",
      permList: [],
      criticalError: null,
      errors: {},
      entity: {
        id: "",
        name: "",
        permissions: {},
        electronicSignature: "",
      },
    };
  },
  methods: {
    triggerSignatureForm() {
      var modalito = new Modal(document.getElementById("sign-modal"));
      modalito.show();
    },
    catchErrors(error) {
      if (error.response.status == 451) {
        return this.triggerSignatureForm();
      }

      this.entity.electronicSignature = "";
      this.errors = error.response.data.errors;

      Swal.fire({
        text: error.response.data.message,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Cerrar",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    getManagers() {
      ApiService.query("index/permissions", {
        params: { permission: "manage_transport" },
      }).then((response) => {
        this.permList = response.data;
      });
    },
    submitFormWithSignature(signature) {
      this.entity.electronicSignature = signature;
      this.submitForm();
    },
    submitForm() {
      this.errors = {};

      if (this.entity.id) {
        ApiService.update("roles", this.entity.id.toString(), this.entity)
          .then((response) => {
            this.$router.back();
          })
          .catch(this.catchErrors);
      } else {
        ApiService.post("roles", this.entity)
          .then((response) => {
            this.$router.back();
          })
          .catch(this.catchErrors);
      }
    },
  },
  mounted() {
    this.getManagers();
    if (this.$route.params.id) {
      this.loading = true;
      ApiService.get("roles", this.$route.params.id.toString()).then(
        (response) => {
          this.entity.id = response.data.id;
          this.entity.name = response.data.name;

          response.data.permissions.forEach((perm) => {
            this.entity.permissions[perm.name] = true;
          });
          this.loading = false;
        }
      );
    } else {
      this.signatureType = "create";
      this.requireExtra = false;
    }
  },
});
